<template>
  <div>
    <v-icon :size="64" class="mb-4">{{ error.icon }}</v-icon>
    <h2>{{ error.label }}</h2>
    <span>{{ error.description }}</span>
  </div>
</template>

<script>
export default {
  name: 'DataNotFound',
  props: {
    error: {
      type: Object,
      required: true
    }
  }
}
</script>
