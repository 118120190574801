<template>
  <v-card>
    <v-card-title>
      <v-row class="justify-space-between">
        <v-col sm="6">
          <h2>{{ $t(bestsellersData.label) }}</h2>
        </v-col>
        <v-col sm="6">
          <v-text-field
            v-model="bestsellersData.options.search"
            @input="debouncedSearch"
            class="pt-0 mt-0"
            append-icon="mdi-magnify"
            :label="$t('Search')"
            :placeholder="$t('Start typing product name...')"
            single-line hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col sm="12">
          <v-data-table
            :headers="bestsellersData.headers"
            :items="bestsellersData.body"
            :search="bestsellersData.options.search"
            :loading="bestsellersData.options.loading"
            item-key="sku"
            width="100"
            class="elevation-1 orders-table"
            :footer-props="{'items-per-page-text':$t('Rows per page:')}"
            no-data-text=""
            hide-default-footer
            sort-by="amount"
            sort-desc
          >
            <template v-if="!bestsellersData.body.length" v-slot:body="">
              <tbody>
                <tr class="v-data-table__empty-wrapper">
                  <td colspan="4" class="py-8">
                    <data-not-found :error="bestsellersData.error" />
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-else v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.sku" class="data-table__row" @click.stop="updateProductEditModal(item.product_id)">
                  <td class="text-start" v-for="key in bestsellersData.headers.map(item => item.value)" :key="item.sku + key">
                    {{ convertDataToView(item, key) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <product-edit-modal v-if="productEdit" :product-id="productEdit" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import DataNotFound from "@/components/DataNotFound.vue";
import ProductEditModal from "@/components/Catalog/ProductEditModal.vue";

export default {
  name: 'DashboardBestsellersListTable',
  components: {ProductEditModal, DataNotFound},
  props: {
    timeRange: {
      type: Object,
      required: true
    }
  },
  data: (vm) => ({
    bestsellersData: {
      label: 'Bestsellers',
      options: {
        loading: true,
        search: '',
        page: 1,
        totalPages: 0,
        itemsPerPage: 5,
      },
      headers: [
        { text: vm.$t('Label'), value: 'name' },
        { text: vm.$t('SKU'), value: 'sku' },
        { text: vm.$t('Total purchases'), value: 'amount' },
        { text: vm.$t('Total revenue'), value: 'revenue', dataWithCurrency: true }
      ],
      body: [],
      error: {
        icon: '',
        label: '',
        description: '',
        data: null
      }
    },
    productEdit: false,
  }),
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
    }),
  },
  methods: {
    ...mapActions({
      loadBestsellersData: 'dashboard/loadBestsellers',
      updateProductInState: 'catalog/category/updateProductData',
    }),
    debouncedSearch: _.debounce(function() {
      this.searched();
    }, 500),
    convertDataToView (row, columnKey) {
      const useCurrency = !!this.bestsellersData.headers.find(item => item.value === columnKey)?.dataWithCurrency;
      if (useCurrency) {
        return `${parseFloat(row[columnKey]).toFixed(2)} ${row.currency}`
      }
      return row[columnKey];
    },
    updateProductData({ product_id, product }) {
      if (!this.products.find(p => p.entity_id === product_id)) {
        return
      }
      this.updateProductInState({ category_id: this.categoryId, product_id, product })
    },
    updateProductEditModal(productId) {
      this.productEdit = productId
    },
  },
  watch: {
    timeRange () {
      if (this.timeRange.date.to && this.timeRange.date.from) {
        this.bestsellersData.options.loading = true;
        this.bestsellersData.error.icon = '';
        this.bestsellersData.error.label = '';
        this.bestsellersData.error.description = '';
        this.loadBestsellersData({ token: this.token, date_from: this.timeRange.date.from, date_to: this.timeRange.date.to})
          .then(response => {
            if (!response.data.length) {
              this.bestsellersData.body = [];
              this.bestsellersData.error.icon = 'mdi-database-sync';
              this.bestsellersData.error.label = this.$t('No results found.');
              this.bestsellersData.error.description = this.$t('Try another selection.');
            } else
            this.bestsellersData.body = response.data;
          })
          .catch(error => {
            console.error(error)
            this.bestsellersData.body = [];
            this.bestsellersData.error.icon = 'mdi-database-remove';
            this.bestsellersData.error.label = this.$t('No results found.');
            this.bestsellersData.error.description = this.$t('Try again later or choose another selection.');
          })
          .finally(() => {
            this.bestsellersData.options.loading = false;
          })
      }
    }
  },
  mounted () {
    this.$bus.$on('catalog-product-update', this.updateProductData);
    this.$bus.$on('catalog-product-edit', this.updateProductEditModal)
  },
  beforeDestroy () {
    this.$bus.$off('catalog-product-update', this.updateProductData)
    this.$bus.$off('catalog-product-edit', this.updateProductEditModal)
  }
}

</script>

<style scoped lang="scss">
  .data-table {
    &__row {
      cursor: pointer;
    }
  }
</style>
