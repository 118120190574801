var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"menu",attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","left":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-medium text-none",attrs:{"color":"primary","width":"100%"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(_vm.selectedOption.timeRange.title))+" ")])]}}])},[_c('v-list',[_vm._l((_vm.timeRangeOptions),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.toggleTimeRange(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),_c('v-list-item',[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","left":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-medium text-none",attrs:{"color":"primary","width":"100%"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Custom date range'))+" ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-time-range-input text-capitalize",attrs:{"label":_vm.$t('from', {symbol: ':'}),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeController.from.value),callback:function ($$v) {_vm.$set(_vm.dateRangeController.from, "value", $$v)},expression:"dateRangeController.from.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateRangeController.from.menu),callback:function ($$v) {_vm.$set(_vm.dateRangeController.from, "menu", $$v)},expression:"dateRangeController.from.menu"}},[_c('v-date-picker',{attrs:{"max":_vm.dateRangeController.to.value},on:{"change":_vm.toggleCustomTimeRange},model:{value:(_vm.dateRangeController.from.value),callback:function ($$v) {_vm.$set(_vm.dateRangeController.from, "value", $$v)},expression:"dateRangeController.from.value"}})],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-time-range-input text-capitalize",attrs:{"label":_vm.$t('to', {symbol: ':'}),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeController.to.value),callback:function ($$v) {_vm.$set(_vm.dateRangeController.to, "value", $$v)},expression:"dateRangeController.to.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateRangeController.to.menu),callback:function ($$v) {_vm.$set(_vm.dateRangeController.to, "menu", $$v)},expression:"dateRangeController.to.menu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateRangeController.from.value},on:{"change":_vm.toggleCustomTimeRange},model:{value:(_vm.dateRangeController.to.value),callback:function ($$v) {_vm.$set(_vm.dateRangeController.to, "value", $$v)},expression:"dateRangeController.to.value"}})],1)],1),_c('v-list-item',[_c('v-btn',{staticClass:"font-weight-medium text-none",attrs:{"color":"primary","width":"calc(50% - 6px)"},on:{"click":function($event){return _vm.onChangeCustomTimeRange('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-medium text-none",attrs:{"color":"primary","width":"calc(50% - 6px)","outlined":""},on:{"click":function($event){return _vm.onChangeCustomTimeRange('save')}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }