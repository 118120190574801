<template>
  <v-menu
    ref="menu"
    transition="slide-y-transition"
    bottom
    offset-y
    :left="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        width="100%"
        class="font-weight-medium text-none"
      >
        {{ $t(selectedOption.timeRange.title) }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in timeRangeOptions"
        :key="i"
        @click="toggleTimeRange(item)"
      >
        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-menu
          transition="slide-y-transition"
          bottom
          offset-y
          :left="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              width="100%"
              class="font-weight-medium text-none"
            >
              {{ $t('Custom date range') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-menu
                v-model="dateRangeController.from.menu"
                :close-on-content-click="false"
                :nudge-left="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeController.from.value"
                    :label="$t('from', {symbol: ':'})"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="custom-time-range-input text-capitalize"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRangeController.from.value"
                  @change="toggleCustomTimeRange"
                  :max="dateRangeController.to.value"
                ></v-date-picker>
              </v-menu>
            </v-list-item>
            <v-list-item>
              <v-menu
                v-model="dateRangeController.to.menu"
                :close-on-content-click="false"
                :nudge-left="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeController.to.value"
                    :label="$t('to', {symbol: ':'})"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="custom-time-range-input text-capitalize"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRangeController.to.value"
                  @change="toggleCustomTimeRange"
                  :min="dateRangeController.from.value"
                ></v-date-picker>
              </v-menu>
            </v-list-item>
            <v-list-item>
              <v-btn
                color="primary"
                width="calc(50% - 6px)"
                class="font-weight-medium text-none"
                @click="onChangeCustomTimeRange('cancel')"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-spacer/>
              <v-btn
                color="primary"
                width="calc(50% - 6px)"
                class="font-weight-medium text-none"
                outlined
                @click="onChangeCustomTimeRange('save')"
              >
                {{ $t('Save') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import moment from "moment";

  const DATE_FORMAT = "YYYY-MM-DD";
  const CUSTOM_DATE_RANGE_DEFAULT_VALUE = {
      menu: false,
      value: moment().format(DATE_FORMAT)
  }

  export default {
    name: "TimeRangeSwitcher",
    props: {
      timeRange: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      timeRangeOptions: [
        { title: 'Today', dateFormat: { key: 'days', value: 0 }},
        { title: 'Yesterday', dateFormat: { key: 'days', value: 1 }},
        { title: 'Last week', dateFormat: { key: 'weeks', value: 1 }},
        { title: 'Last month', dateFormat: { key: 'months', value: 1 }},
        { title: 'Last year', dateFormat: { key: 'years', value: 1 }},
      ],
      selectedOption: {
        timeRange: null,
      },
      dateRangeController: {
        from: {...CUSTOM_DATE_RANGE_DEFAULT_VALUE},
        to: {...CUSTOM_DATE_RANGE_DEFAULT_VALUE}
      }
    }),
    methods: {
      setStartedData () {
        this.toggleTimeRange({ title: 'Last week', dateFormat: { key: 'weeks', value: 1 }});
      },
      toggleTimeRange(timeRange) {
        const currentDate = moment();

        this.dateRangeController = {
          from: {...CUSTOM_DATE_RANGE_DEFAULT_VALUE},
          to: {...CUSTOM_DATE_RANGE_DEFAULT_VALUE}
        }
        this.selectedOption.timeRange = {...timeRange};
        this.$emit('update:timeRange', {
          title: timeRange.title,
          date: {
            to: currentDate.format(DATE_FORMAT),
            from: currentDate.subtract(timeRange.dateFormat.value, timeRange.dateFormat.key).format(DATE_FORMAT),
          }
        });
      },
      toggleCustomTimeRange () {
        this.dateRangeController.from.menu = false;
        this.dateRangeController.to.menu = false;
        this.selectedOption.timeRange.date = {
          from: this.dateRangeController.from.value,
          to: this.dateRangeController.to.value,
        }
      },
      onChangeCustomTimeRange (action) {
        if (action === 'cancel') {
          this.setStartedData();
          return this.$refs.menu.save();
        }
        this.selectedOption.timeRange.title = `${moment(this.selectedOption.timeRange.date.from).format('ll')} - ${moment(this.selectedOption.timeRange.date.to).format('ll')}`;
        this.$emit('update:timeRange', {
          title: 'Custom',
          date: {
            to: this.dateRangeController.to.value,
            from: this.dateRangeController.from.value,
          },
        });
        this.$refs.menu.save();
      }
    },
    beforeMount() {
      this.setStartedData();
    }
  }
</script>
