<template>
  <section>
    <v-row class="justify-space-between">
      <v-col cols="auto"><h1 class="font-weight-medium heading text-h4">{{ $t('Dashboard') }}</h1></v-col>
      <v-col cols="3" style="min-width: 248px"><time-range-switcher :time-range.sync="timeRange" /></v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <dashboard-charts :time-range="timeRange" />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <dashboard-bestsellers-list-table :time-range="timeRange" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import TimeRangeSwitcher from "@/components/TimeRangeSwitcher.vue";
import DashboardCharts from "@/components/Dashboard/DashboardCharts.vue";
import DashboardBestsellersListTable from "@/components/Dashboard/DashboardBestsellersListTable.vue";

export default {
  name: 'Dashboard',
  components: {
    TimeRangeSwitcher,
    DashboardCharts,
    DashboardBestsellersListTable
  },
  data: () => ({
    timeRange: {
      title: '',
      date: {
        from: '',
        to: ''
      }
    },
  })
}
</script>

<style scoped lang="scss">
  .v-list-item {
    cursor: pointer;
  }
</style>
